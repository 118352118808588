import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { BsHouseDoor } from "react-icons/bs";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import { GiConsoleController } from "react-icons/gi";
import { BiMinus } from "react-icons/bi";
import {
  AiOutlineLeft,
  AiOutlinePlus,
  AiOutlineRight,
  AiOutlineHeart,
} from "react-icons/ai";
import { addtoCart, decrementCart } from "../../redux/cart/CartSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import SingleProduct from "../../component/SingleProduct";

const CategorySearch = () => {
  const { CartItems, ProductTotal } = useSelector((state) => state.Cart);

  const { categoryTotal } = useSelector((state) => state.Category);

  const [products, setProducts] = useState([]);
  const [dummy, setDummy] = useState([]);

  const [visible, setVisible] = useState(false);
  const [curritemIndex, setcurritemIndex] = useState(0);
  const [currentProduct, setCurrentProduct] = useState("");
  const [ProductLoading, setProductLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const searchUrl = params.catsearch;
    const singleCat = categoryTotal.find((cat) => {
      return cat.slugUrl === searchUrl;
    });
    const catId = singleCat._id;
    if (catId) {
      try {
        const fetchData = async () => {
          const url = `${Baseurl}/api/v1/grocery/grocerybycatid/${catId}`;
          const fetchproducts = await axios.get(url);
          try {
            if (fetchproducts.data.success === true) {
              const categoryproducts = fetchproducts.data.grocery.filter(
                (data) =>
                  data.OutOfStack === false && data.PackSizes.length !== 0
              );

              CartItems.forEach((cartitem) => {
                let itemIndex = -1;
                itemIndex = categoryproducts.findIndex(
                  (product) =>
                    String(product._id) === String(cartitem.ProductId)
                );
                if (itemIndex >= 0) {
                  let packIndex = -1;
                  packIndex = categoryproducts[itemIndex].PackSizes.findIndex(
                    (pack) => String(pack._id) === String(cartitem.packsizeId)
                  );
                  if (packIndex >= 0) {
                    categoryproducts[itemIndex].PackSizes[
                      packIndex
                    ].CartQuantity = cartitem.Qty;
                  }
                }
              });
              setProducts(categoryproducts);
              setProductLoading(false);
            }
          } catch (error) {}
        };
        fetchData();
      } catch (error) {}
    } else {
    }
  }, [params.catsearch, ProductLoading]);

  const optionsProductset = (product, itemIndex) => {
    setCurrentProduct(product);
    setcurritemIndex(itemIndex);
    setVisible(!visible);
  };

  const addtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let productsall = [...products];
      productsall[itemIndex].PackSizes[packIndex].CartQuantity += 1;
      setDummy(productsall);
      setProducts([...productsall]);
    } catch (error) {}

    const formData = {
      ProductId: hotPro._id,
      ProductName: hotPro.ItemName,
      CatName: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      ItemName: hotPro.ItemName,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      Description: hotPro.Description,
      ImgUrl: hotPro.PackSizes[packIndex].ImgUrlMbl,
      Price: hotPro.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      TotalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      Cashback: hotPro.Cashback,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      maximumQuantity: hotPro.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let productsall = [...products];
      productsall[itemIndex].PackSizes[packIndex].CartQuantity -= 1;
      setDummy(productsall);
      setProducts([...productsall]);
    } catch (error) {}

    const formData = {
      ItemName: hotPro.ItemName,
      Category: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      totalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      totalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      totalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      CartQuantity: 1,
      ImgUrlMbl: hotPro.PackSizes[packIndex].ImgUrlMbl,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      productId: hotPro._id,
      maximumQuantity: hotPro.maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  return (
    <>
      {ProductLoading ? (
        <>
          <div className="d-flex justify-content-center loadingMain">
            {/* <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
              Product is being fetched
            </h4> */}
            <BeatLoader
              color={"#36d7b7"}
              loading={ProductLoading}
              size={10}
              className="mt-2 mx-2"
            />
          </div>
        </>
      ) : (
        <>
          {products.length > 0 ? (
            <>
              {" "}
              <section className="wishlist-section section-b-space">
                <div className="container-fluid-lg">
                  <div className="row g-sm-3 g-2">
                    {products.map((product, itemIndex) => (
                      <div
                        className="col-xxl-2 col-lg-2 col-md-6 col-6 product-box-contain "
                        key={itemIndex}
                      >
                        <SingleProduct
                          itemIndex={itemIndex}
                          product={product}
                          addtocartPress={addtocartPress}
                          decrementtocartPress={decrementtocartPress}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default CategorySearch;
